.tabContainer {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  justify-content: space-between;
}

.tabs {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
}

.tabItem {
  margin-right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgb(217 231 234 / 100%);
  cursor: pointer;
}

.tabItem_Active {
  background-color: rgb(242 247 253 / 100%);
}

.closeBtn {
  background-color: rgb(194 206 210 / 35%);
  cursor: pointer;
  padding: 8px;
  border-radius: 20px;
  margin-left: 10px;
}

.tabControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.arrow {
  height: 20px;
  width: 20px;
}

.arrow:hover {
  cursor: pointer;
}

.leftArrow {
  transform: rotate(90deg);
}

.rightArrow {
  transform: rotate(-90deg);
}

.triangle {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.burger {
  position: relative;
  margin-right: 10px;
}

.disabled {
  color: gray;
}

.closeMenuContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #0f9119;
  background-color: rgb(217 231 235 / 100%);
  height: 80px;
  width: 270px;
  top: 30px;
  z-index: 10000;
  left: -220px;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.closeMenuContainer2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #0f9119;
  background-color: rgb(217 231 235 / 100%);
  height: auto;
  max-height: 100px;
  overflow-y: auto;
  width: 270px;
  top: 30px;
  z-index: 10000;
  left: -240px;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.closeItem {
  width: auto;
  height: 30px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Ubuntu-R;
  color: #1f4b3c;
}

.closeItem2 {
  width: auto;
  height: 30px;
  padding: 5px 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Ubuntu-R;
  color: #1f4b3c;
}

.closeItem:hover {
  background-color: #a2e3df;
}

.closeItem2:hover {
  background-color: #a2e3df;
}

.rotate {
  transform: rotate(180deg);
}

div[disabled] {
  pointer-events: none;
}
